/*-------------------------------------------------------------

  #  Template Name: Buzne – Corporate Business Bootstrap 5 Template
  #  Author   Name: Hastech
  #  Version: 1.0

-------------------------------------------------------------

  #   CSS INDEX
========================================================

    [ 01. Bootstrap CSS ]

    [ 02. Template default CSS ]

    [ 03. header CSS ]

    [ 04. hero Slider CSS ]

    [ 05. Component CSS ]

    [ 05.1 Component button CSS ]

    [ 05.2 Component form CSS ]

    [ 05.3 Component title CSS ]

    [ 05.4 Component card CSS ]

    [ 05.5 Component massonary CSS ]

    [ 05.6 Component brand CSS ]

    [ 05.7 Component feature CSS ]

    [ 05.8 Component about CSS ]

    [ 05.9 Component service CSS ]

    [ 05.10 Component testimonial CSS ]

    [ 05.11 Component funfact CSS ]

    [ 05.12 Component team CSS ]

    [ 05.13 Component blog CSS ]

    [ 05.14 Component news-letter CSS ]

    [ 05.15 Component banner CSS ]

    [ 05.16 Component contact CSS ]

    [ 05.17 Component accordion CSS ]

    [ 05.18 Component pagination CSS ]

    [ 05.19 Component sidebar widget CSS ]

    [ 05.20 Component blog details CSS ]

    [ 06 Component sub menu CSS ]

    [ 07 Component footer CSS ]


-----------------------------------------------------*/

/*--------------------------------------
/*  [ 01. Bootstrap CSS ]
----------------------------------------*/

@import "./bootstrap/functions";
@import "variabls";
@import "custom-variables";
@import "./bootstrap/bootstrap";

/*--------------------------------------
/*  Bootstrap Css End
----------------------------------------*/

/*--------------------------------------
/*   [ 02. Template default CSS ]
----------------------------------------*/

@import "mixin";
@import "common";

/*--------------------------------------
/*   [ 03. header CSS ]
----------------------------------------*/

@import "header";
/*--------------------------------------
/*   [ 04. hero Slider CSS ]
----------------------------------------*/

@import "hero";

/*--------------------------------------
/*   [ 05. Component CSS ]
----------------------------------------*/

@import "component";

.active-link {
  background: linear-gradient(to right, #fb2d49 0%, #fa6340 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-weight: bold !important;
}

.cog-abuse {
  display: none !important;
}

.cog-branding {
  display: none !important;
}
