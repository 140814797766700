.blog-card {
  .thumb {
    overflow: hidden;
    padding: 30px 30px;
    border-radius: 6px;
    a {
      display: block;
      img {
        width: 100%;
        border-radius: 6px;
      }
    }
  }
  &:hover {
    .blog-content {
      background: $lime-600;
    }

    img {
      transform: scale(1.05);
    }
  }
}

.blog-content {
  max-width: 330px;
  background: $gray-600;
  padding: 35px 20px;
  @include max-screen(576, 767) {
    padding: 35px 20px;
  }
  margin: -40px auto auto;
  position: relative;
  z-index: 2;
  border-radius: 6px;
  transition: all 0.5s ease-in-out;
  .blog-meta {
    display: inline-block;
    font-family: "Metropolis Light";
    color: $sub-headings-color;
    line-height: 1.5;
    margin-bottom: 0.3125rem;
    font-size: 16px;
    @include max-screen(576, 767) {
      font-size: 14px;
    }
  }
  .separator{
    margin: 0 8px;
  }
  .title {
    color: $card-headings-color;
    &:hover {
      color: $primary;
    }
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 0px;
    font-size: 24px;

    @include max-screen(992, 1199) {
      font-size: 20px;
    }
    @include max-screen(767) {
      font-size: 20px;
    }
    @include max-screen(576, 767) {
      font-size: 18px;
    }
    a {
      color: inherit;
    }
  }
}

.wrapper{
  .blog_item:hover img {
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .blog_item {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 20px;

  }
  .blog_item {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
  .blog_meta li {
    display: inline-block;
    padding-right: 15px;
  }
  .blog_meta li:last-child {
    float:right;
    padding-top: 3px;
  }
  .blog_content p {
    font-size: 13px;
    font-weight: 400;
    height: 96px;
    margin: 0 0 10px;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 10px;
    overflow: inherit;
  }
  .blog_meta a {
    color: #444444;
  }
  .blog_meta li a i {
    color: #5957cd;
    font-size: 18px;
    vertical-align: middle;
    transition: all 0.5s ease 0s;
    margin-right: 8px;
  }
  .blog_meta li a{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
  }
  .blog_meta li a img {
    max-width: 20px;
    height: 22px;
    margin-right: 6px;
    margin-left: 8px;
  }
  .blog_img {
    border-radius: 10px 10px 0 0;
    overflow: hidden;

  }
  .blog_img img {
    height: 254px;
    zoom: 1;
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all 0.5s ease 0s;
    width:100%;
  }
  .blog_meta {
    margin-top:10px;
    background-color: #f1f1f1;
    border-radius: 0 0 10px 10px;
    display: block;
    width: 100%;
  }
  .svg_icon_right{
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(50%);
    cursor: pointer;
  }
  @media (max-width: 767px) {
    .svg_icon_right {
      display: none;
    }
    .svg_icon_left{
      display: none;
    }
  }
  .svg_icon_left{
    position: absolute;
    left: -26px;
    top: 50%;
    transform: translateY(50%);
    cursor: pointer;
  }
  .slick-dots li button:before {
    display: block;
    margin-top: 40px;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: red;
    text-indent: -9999px;
  }
  .slick-slider .slick-track{
    display: flex;
    gap: 33px;
  }
  .blog_title{
    padding-top: 10px;
    padding-left: 14px;
    a{
      color: black;
      font-weight: 600;
    }
  }
  .blog_tag{
    mark{
      padding: 6px 14px 6px 14px;
      border-radius: 8px;
      margin-left: 14px;
    }
  }
  mark{
    background: linear-gradient(to right, #fb2d49 0%, #fa6340 100%);
    color: white;
  }
  @media (max-width: 767px) {
    max-width: none;
    .svg_icon_right .svg_icon_left {
      display: none;
    }
  }
  @media (max-width: 1199px) {
    width: 97% !important;
  }
}
